import React from 'react';

function useActive() {
  const [isActive, setActive] = React.useState(false);
  const bind = React.useMemo(() => ({
    onMouseDown: e => void setActive(true),
    onMouseUp: e => void setActive(false)
  }), []);
  return [isActive, bind];
}

export default useActive;