import React from 'react';

function useTouch() {
  const [isTouched, setTouched] = React.useState(false);
  const bind = React.useMemo(() => ({
    onTouchStart: e => void setTouched(true),
    onTouchEnd: e => void setTouched(false)
  }), []);
  return [isTouched, bind];
}

export default useTouch;