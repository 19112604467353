import React from 'react';

function useFocus() {
  const [isFocused, setFocused] = React.useState(false);
  const bind = React.useMemo(() => ({
    onFocus: e => void setFocused(true),
    onBlur: e => void setFocused(false)
  }), []);
  return [isFocused, bind];
}

export default useFocus;